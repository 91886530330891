<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2266 6.5766L13.2266 2.8266M13.2266 2.8266H9.47656M13.2266 2.8266L8.22656 7.8266M6.35156 4.0766H4.97656C3.92646 4.0766 3.40141 4.0766 3.00033 4.28096C2.64753 4.46072 2.36069 4.74756 2.18093 5.10037C1.97656 5.50145 1.97656 6.0265 1.97656 7.0766V11.0766C1.97656 12.1267 1.97656 12.6517 2.18093 13.0528C2.36069 13.4056 2.64753 13.6925 3.00033 13.8722C3.40141 14.0766 3.92646 14.0766 4.97656 14.0766H8.97656C10.0267 14.0766 10.5517 14.0766 10.9528 13.8722C11.3056 13.6925 11.5924 13.4056 11.7722 13.0528C11.9766 12.6517 11.9766 12.1267 11.9766 11.0766V9.7016" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconExtLink'
}
</script>
